.test {
    background-color: hotpink;
    color: green;
}

.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    border-radius: 0% !important;
    -webkit-box-shadow: 0px 35px 68px -33px rgba(0, 0, 0, 0.56);
    -moz-box-shadow: 0px 35px 68px -33px rgba(0, 0, 0, 0.56);
    box-shadow:0px 43px 48px -30px rgba(0, 0, 0, 0.56) !important
}

.page-item .page-link,
.page-item span {
    border-radius: 10% !important;
}

li > button {
    background-color: #69d97a !important;
    color: white !important;
    text-align: center !important;
    display: inline-block !important;
    cursor: pointer !important;
    border-radius: 20% !important;
    white-space: nowrap !important;
    padding: 10px !important;
    width: 100% !important;
}

p {
    font-size: 15px;
    font-weight: 800;
}

li > button:hover{
    background-color: #263d4b !important;
}

.page-item.active .page-link{
    color: #fff;
    background-color: #2c50d9 !important;
    border-color: #2c50d9;
}

.modal-header{
    background-color: #2c50d9;
    
}

.modal-title{
    color: white !important;
}

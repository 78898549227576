.languageSelect {
    display: flex;
    column-gap: 10px;
    justify-content: center;
    direction: ltr;
}

.languageSelect label {
    cursor: pointer;
}

.languageSelect label:hover {
    color: black;
}


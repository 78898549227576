@media (min-width: 768px) {
    .navbar-vertical.navbar-expand-md:lang(ar) {
        right: 0;
    }
    .navbar-vertical.navbar-expand-md.fixed-left + .main-content:lang(ar) {
        margin-right: 250px;
        margin-left: 0px;
    }

    .sidebar-name{
        margin-right: 5px;
    }
}